import { sendPost } from "./httpService";

export const createCall = async (areaId: string) => {
    const response = await sendPost(`${import.meta.env.PUBLIC_INTERCOM_API_URL}/v2/areas/${areaId}/calls`);

    if (!response.ok) {
        throw new Error(`Error creating call! Status: ${response.status}`);
    }
    
    return response.headers.get("Call-Id");
}