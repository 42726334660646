import { useEffect, useState } from "react";
import DialerButton from "./buttons/dialer-button"
import { destinationsStore } from '../framework/stores/app-store';
import { useStore } from '@nanostores/react';
import { navigate } from "astro:transitions/client";
import { createCall } from "@root/services/calls-service";
import { callIdStore } from "@root/framework/stores/calls-store";
import { userRootDataStore } from "@root/framework/stores/user-store";

const Dialer = () => {

    const [dialCode, setDialCode] = useState("");
    const $callIds = useStore(destinationsStore);
    const $userRootData = useStore(userRootDataStore);
    const [hasIntercomProfile, setHasIntercomProfile] = useState(false);
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        if (!$userRootData) return;
        setHasIntercomProfile($userRootData.areasProfiles.find(area => area.profile == "INTERCOM") !== undefined);
    }, [$userRootData])

    useEffect(() => {
        const lowerCaseUserAgent = navigator.userAgent.toLowerCase();
        setIsMobileDevice(lowerCaseUserAgent.includes("iphone") || lowerCaseUserAgent.includes("android"));
    }, [])

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const button = e.currentTarget as HTMLButtonElement;

        if (button.name == "digit") {
            setDialCode(dialCode + button.innerText);
        }
        else if (button.name == "delete") {
            setDialCode(dialCode.slice(0, dialCode.length - 1));
        }
        else if (button.name == "call") {
            const areaId = $callIds.callbook.find(id => id.dialCode == dialCode)?.areaId;
            if (!areaId) {
                alert("No se encontró ningún departamento con ese número");
                return;
            };
            const callId = await createCall(areaId);
            callIdStore.set(callId);
            navigate(`/calls/${callId}`);
        }
    }

    return (
        <>
            {hasIntercomProfile &&
                <div>
                    <div className="border border-[#D1D0D0] rounded-lg flex w-full h-16">
                        <input
                            type="number"
                            className="font-bold text-gray-800 text-xl bg-transparent flex-grow items-center text-center"
                            id="area-number"
                            maxLength={4}
                            placeholder="- - - -"
                            readOnly
                            value={dialCode}
                        />
                    </div>
                    <div className="border border-[#D1D0D0] rounded-lg mt-6">
                        <div
                            id="caller-form"
                            className="w-full h-full p-10"
                        >
                            <div className="max-w-sm mx-auto text-center mb-3">
                                <p className="text-gray-800 font-semibold uppercase">
                                    Ingresa número de departamento
                                </p>
                            </div>
                            <div className="w-full h-full border-t border-[#D1D0D0]">
                                <div
                                    className="mt-4 grid grid-cols-3 gap-8 place-items-center text-gray-500 w-full h-full dark:text-gray-400"
                                >
                                    <DialerButton text="1" handleClick={handleClick} />
                                    <DialerButton text="2" handleClick={handleClick} />
                                    <DialerButton text="3" handleClick={handleClick} />
                                    <DialerButton text="4" handleClick={handleClick} />
                                    <DialerButton text="5" handleClick={handleClick} />
                                    <DialerButton text="6" handleClick={handleClick} />
                                    <DialerButton text="7" handleClick={handleClick} />
                                    <DialerButton text="8" handleClick={handleClick} />
                                    <DialerButton text="9" handleClick={handleClick} />
                                    <DialerButton text="x" name="delete" handleClick={handleClick} />
                                    <DialerButton text="0" handleClick={handleClick} />
                                    <DialerButton text="c" name="call" type="submit" handleClick={handleClick} />
                                </div>
                            </div>
                            <input type="hidden" id="hidden-area-number" name="area-number" />
                        </div>
                    </div>
                </div>}
            {!hasIntercomProfile &&
                <div className="flex flex-col items-center justify-center p-4 gap-4">
                    <div className="p-4 text-sm text-pink-950 rounded-lg bg-pink-100">
                        <span className="font-medium">Información</span> No tienes un perfil de Citofonía asignado.
                    </div>
                    {isMobileDevice &&
                        <a href="softlight-ci://" className="w-full h-14 flex justify-center items-center text-black bg-[#FCD674] hover:bg-[#f1d898] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-6 py-2 text-center dark:bg-[#FCD674] dark:hover:bg-[#FCD674] dark:focus:ring-primary-800">
                            <span>Continúa desde Comunidad Inteligente.</span>
                            <svg className="ml-2 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="currentColor"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h560v-280h80v280q0 33-23.5 56.5T760-120H200Zm188-212-56-56 372-372H560v-80h280v280h-80v-144L388-332Z" /></svg>
                        </a>
                    }
                </div>
            }
        </>
    )
}

export default Dialer